<template>
    <b-row>
        <b-col cols="12">
            <div
                class="d-sm-flex justify-content-between align-items-center mb-4"
            >
                <feather
                    type="arrow-left-circle"
                    v-b-popover.hover.top="'Presione esc'"
                    role="button"
                    class="text-secondary"
                    size="2rem"
                    @click="back()"
                ></feather>
                <div class="d-flex flex-row">
                    <b-button
                        variant="secondary"
                        :disabled="isLoading"
                        v-b-popover.hover.top="'Presione esc'"
                        size="md"
                        class="mr-4"
                        @click="back()"
                    >
                        Cancelar
                    </b-button>

                    <b-button
                        variant="primary"
                        :disabled="isLoading || read_only"
                        size="md"
                        v-b-popover.hover.top="
                            'Presione ctrl+shift+s o cmd+shift+s'
                        "
                        class="d-flex align-items-center"
                        @click="save"
                    >
                        <feather
                            type="save"
                            size="1rem"
                            class="mr-2 text-blue-active"
                        />
                        <span
                            v-if="isLoading"
                            class="d-sm-flex align-items-center justify-content-center"
                        >
                            <b-spinner small></b-spinner>
                            <span class="ml-2">Guardando...</span>
                        </span>
                        <span v-else>Guardar</span>
                    </b-button>
                </div>
            </div>
        </b-col>
        <b-col
            cols="12"
            lg="12"
            class="d-flex align-items-stretch"
            style="min-height: 70vh"
        >
            <b-card class="mb-4 w-100" v-hotkey="keymap">
                <div>
                    <b-form class="p-5">
                        <div class="d-flex">
                            <div>
                                <h3 class="mb-2">Datos del usuario</h3>
                                <div class="text-gray mb-5">
                                    Por favor registre los datos del usuario
                                    para continuar
                                </div>
                            </div>
                        </div>
                        <b-row>
                            <b-col cols="12" class="mb-5">
                                <b-form-group>
                                    <label for="enabled">Habilitado</label>
                                    <b-form-checkbox
                                        id="enabled"
                                        size="lg"
                                        switch
                                        v-model="$v.usuario.enabled.$model"
                                        :value="true"
                                        :unchecked-value="false"
                                        :disabled="read_only"
                                    >
                                    </b-form-checkbox>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="4" lg="4" xl="4">
                                <b-form-group>
                                    <label for="tipo_documento_id"
                                        >Tipo documento
                                        <small style="color: red"
                                            >*</small
                                        ></label
                                    >
                                    <jautocomplete
                                        id="tipo_documento_id"
                                        :items="tipos_documento"
                                        item-text="descripcion"
                                        item-value="id"
                                        :disabled="read_only"
                                        open-on-focus
                                        v-model="
                                            $v.usuario.tipo_documento_id.$model
                                        "
                                        :state="
                                            validateState('tipo_documento_id')
                                        "
                                    />

                                    <!-- <b-form-select
                                        id="tipo_documento_id"
                                        :options="tipos_documento"
                                        text-field="descripcion"
                                        value-field="id"
                                        :disabled="read_only"
                                        v-model="
                                            $v.usuario.tipo_documento_id.$model
                                        "
                                        :state="
                                            validateState('tipo_documento_id')
                                        "
                                    >
                                        <template #first>
                                            <b-form-select-option
                                                :value="null"
                                                disabled
                                                >Seleccione</b-form-select-option
                                            >
                                        </template>
                                    </b-form-select> -->
                                    <div
                                        class="invalid-field mt-1"
                                        v-if="
                                            $v.usuario.tipo_documento_id.$error
                                        "
                                    >
                                        Campo requerido
                                    </div>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="4" lg="4" xl="4">
                                <b-form-group>
                                    <label for="numero_documento"
                                        >Número documento
                                        <small style="color: red"
                                            >*</small
                                        ></label
                                    >
                                    <b-form-input
                                        id="numero_documento"
                                        type="number"
                                        placeholder="Ej: 26186920835"
                                        @focus="onFocused"
                                        v-model="
                                            $v.usuario.numero_documento.$model
                                        "
                                        :state="
                                            validateState('numero_documento')
                                        "
                                        :disabled="read_only"
                                    >
                                    </b-form-input>
                                    <div
                                        class="length-field mt-1"
                                        v-if="
                                            !$v.usuario.numero_documento
                                                .$anyError
                                        "
                                    >
                                        ({{
                                            $v.usuario.numero_documento.$model
                                                .length
                                        }}
                                        /
                                        {{
                                            $v.usuario.numero_documento.$params
                                                .maxLength.max
                                        }})
                                    </div>
                                    <b-form-invalid-feedback
                                        v-if="
                                            !$v.usuario.numero_documento
                                                .minLength
                                        "
                                    >
                                        Este campo debe tener al menos
                                        {{
                                            $v.usuario.numero_documento.$params
                                                .minLength.min
                                        }}
                                        caracteres
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                        v-if="
                                            !$v.usuario.numero_documento
                                                .maxLength
                                        "
                                    >
                                        Este campo debe tener máximo
                                        {{
                                            $v.usuario.numero_documento.$params
                                                .maxLength.max
                                        }}
                                        caracteres
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                        v-if="
                                            !$v.usuario.numero_documento
                                                .required
                                        "
                                    >
                                        Campo requerido
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="4" lg="4" xl="4">
                                <b-form-group>
                                    <label for="email"
                                        >E-mail
                                        <small style="color: red"
                                            >*</small
                                        ></label
                                    >
                                    <b-form-input
                                        id="email"
                                        type="text"
                                        placeholder="Ej: correo@ejemplo.com"
                                        v-model="$v.usuario.email.$model"
                                        @focus="onFocused"
                                        :state="validateState('email')"
                                        :disabled="read_only"
                                    ></b-form-input>
                                    <div
                                        class="length-field mt-1"
                                        v-if="!$v.usuario.email.$anyError"
                                    >
                                        ({{ $v.usuario.email.$model.length }} /
                                        {{
                                            $v.usuario.email.$params.maxLength
                                                .max
                                        }})
                                    </div>
                                    <b-form-invalid-feedback
                                        v-if="!$v.usuario.email.maxLength"
                                    >
                                        Este campo debe tener máximo
                                        {{
                                            $v.usuario.email.$params.maxLength
                                                .max
                                        }}
                                        caracteres
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                        v-if="!$v.usuario.email.email"
                                    >
                                        E-mail inválido
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                        v-if="!$v.usuario.email.required"
                                    >
                                        Campo requerido
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="mt-4">
                            <b-col cols="12" md="3" lg="3" xl="3">
                                <b-form-group>
                                    <label for="nombre1"
                                        >Primer nombre
                                        <small style="color: red"
                                            >*</small
                                        ></label
                                    >
                                    <b-form-input
                                        id="nombre1"
                                        type="text"
                                        placeholder="Ej: Jhon"
                                        @focus="onFocused"
                                        v-model="$v.usuario.nombre1.$model"
                                        :state="validateState('nombre1')"
                                        :disabled="read_only"
                                    ></b-form-input>
                                    <div
                                        class="length-field mt-1"
                                        v-if="!$v.usuario.nombre1.$anyError"
                                    >
                                        ({{ $v.usuario.nombre1.$model.length }}
                                        /
                                        {{
                                            $v.usuario.nombre1.$params.maxLength
                                                .max
                                        }})
                                    </div>
                                    <b-form-invalid-feedback
                                        v-if="!$v.usuario.nombre1.maxLength"
                                    >
                                        Este campo debe tener máximo
                                        {{
                                            $v.usuario.nombre1.$params.maxLength
                                                .max
                                        }}
                                        caracteres
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                        v-if="!$v.usuario.nombre1.required"
                                    >
                                        Campo requerido
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="3" lg="3" xl="3">
                                <b-form-group>
                                    <label for="nombre2">Segundo nombre</label>
                                    <b-form-input
                                        id="nombre2"
                                        type="text"
                                        placeholder="Ej: Jhon"
                                        @focus="onFocused"
                                        v-model="$v.usuario.nombre2.$model"
                                        :disabled="read_only"
                                        :state="validateState('nombre2')"
                                    ></b-form-input>
                                    <div
                                        class="length-field mt-1"
                                        v-if="!$v.usuario.nombre2.$anyError"
                                    >
                                        ({{ $v.usuario.nombre2.$model.length }}
                                        /
                                        {{
                                            $v.usuario.nombre2.$params.maxLength
                                                .max
                                        }})
                                    </div>
                                    <b-form-invalid-feedback
                                        v-if="!$v.usuario.nombre2.maxLength"
                                    >
                                        Este campo debe tener máximo
                                        {{
                                            $v.usuario.nombre2.$params.maxLength
                                                .max
                                        }}
                                        caracteres
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="3" lg="3" xl="3">
                                <b-form-group>
                                    <label for="apellido1"
                                        >Primer apellido
                                        <small style="color: red"
                                            >*</small
                                        ></label
                                    >
                                    <b-form-input
                                        id="apellido1"
                                        type="text"
                                        placeholder="Ej: Doe"
                                        @focus="onFocused"
                                        v-model="$v.usuario.apellido1.$model"
                                        :state="validateState('apellido1')"
                                        :disabled="read_only"
                                    ></b-form-input>
                                    <div
                                        class="length-field mt-1"
                                        v-if="!$v.usuario.apellido1.$anyError"
                                    >
                                        ({{
                                            $v.usuario.apellido1.$model.length
                                        }}
                                        /
                                        {{
                                            $v.usuario.apellido1.$params
                                                .maxLength.max
                                        }})
                                    </div>
                                    <b-form-invalid-feedback
                                        v-if="!$v.usuario.apellido1.maxLength"
                                    >
                                        Este campo debe tener máximo
                                        {{
                                            $v.usuario.apellido1.$params
                                                .maxLength.max
                                        }}
                                        caracteres
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                        v-if="!$v.usuario.apellido1.required"
                                    >
                                        Campo requerido
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="3" lg="3" xl="3">
                                <b-form-group>
                                    <label for="apellido2"
                                        >Segundo apellido</label
                                    >
                                    <b-form-input
                                        id="apellido2"
                                        type="text"
                                        placeholder="Ej: Doe"
                                        @focus="onFocused"
                                        v-model="$v.usuario.apellido2.$model"
                                        :disabled="read_only"
                                        :state="validateState('apellido2')"
                                    ></b-form-input>
                                    <div
                                        class="length-field mt-1"
                                        v-if="!$v.usuario.apellido2.$anyError"
                                    >
                                        ({{
                                            $v.usuario.apellido2.$model.length
                                        }}
                                        /
                                        {{
                                            $v.usuario.apellido2.$params
                                                .maxLength.max
                                        }})
                                    </div>
                                    <b-form-invalid-feedback
                                        v-if="!$v.usuario.apellido2.maxLength"
                                    >
                                        Este campo debe tener máximo
                                        {{
                                            $v.usuario.apellido2.$params
                                                .maxLength.max
                                        }}
                                        caracteres
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="mt-4">
                            <b-col
                                cols="12"
                                :md="!usuario.id ? 4 : 6"
                                :lg="!usuario.id ? 4 : 6"
                                :xl="!usuario.id ? 4 : 6"
                            >
                                <b-form-group>
                                    <label for="telefono"
                                        >Teléfono
                                        <small style="color: red"
                                            >*</small
                                        ></label
                                    >
                                    <b-form-input
                                        id="telefono"
                                        type="number"
                                        placeholder="Ej: 7852532"
                                        v-model="$v.usuario.telefono.$model"
                                        @focus="onFocused"
                                        :state="validateState('telefono')"
                                        :disabled="read_only"
                                    ></b-form-input>
                                    <div
                                        class="length-field mt-1"
                                        v-if="!$v.usuario.telefono.$anyError"
                                    >
                                        ({{ $v.usuario.telefono.$model.length }}
                                        /
                                        {{
                                            $v.usuario.telefono.$params
                                                .maxLength.max
                                        }})
                                    </div>
                                    <b-form-invalid-feedback
                                        v-if="!$v.usuario.telefono.maxLength"
                                    >
                                        Este campo debe tener máximo
                                        {{
                                            $v.usuario.telefono.$params
                                                .maxLength.max
                                        }}
                                        caracteres
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                            <b-col
                                cols="12"
                                :md="!usuario.id ? 4 : 6"
                                :lg="!usuario.id ? 4 : 6"
                                :xl="!usuario.id ? 4 : 6"
                            >
                                <b-form-group>
                                    <label for="username"
                                        >Nombre de usuario
                                        <small style="color: red"
                                            >*</small
                                        ></label
                                    >
                                    <b-form-input
                                        id="username"
                                        type="text"
                                        placeholder="Ej: nombreusuario"
                                        v-model="$v.usuario.username.$model"
                                        @input="
                                            (v) => {
                                                $v.usuario.username.$model =
                                                    v.replace(/\s/g, '')
                                            }
                                        "
                                        @focus="onFocused"
                                        :state="validateState('username')"
                                        :disabled="read_only"
                                    ></b-form-input>
                                    <div
                                        class="length-field mt-1"
                                        v-if="!$v.usuario.username.$anyError"
                                    >
                                        ({{ $v.usuario.username.$model.length }}
                                        /
                                        {{
                                            $v.usuario.username.$params
                                                .maxLength.max
                                        }})
                                    </div>
                                    <b-form-invalid-feedback
                                        v-if="!$v.usuario.username.minLength"
                                    >
                                        Este campo debe tener mínimo
                                        {{
                                            $v.usuario.username.$params
                                                .minLength.min
                                        }}
                                        caracteres
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                        v-if="!$v.usuario.username.maxLength"
                                    >
                                        Este campo debe tener máximo
                                        {{
                                            $v.usuario.username.$params
                                                .maxLength.max
                                        }}
                                        caracteres
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                        v-if="!$v.usuario.username.required"
                                    >
                                        Campo requerido
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                            <b-col
                                v-if="!usuario.id"
                                cols="12"
                                md="4"
                                lg="4"
                                xl="4"
                            >
                                <b-form-group>
                                    <label for="password"
                                        >Contraseña
                                        <small style="color: red"
                                            >*</small
                                        ></label
                                    >
                                    <b-input-group>
                                        <b-form-input
                                            id="password"
                                            v-model="$v.usuario.password.$model"
                                            :type="
                                                showPassword
                                                    ? 'text'
                                                    : 'password'
                                            "
                                            :state="validateState('password')"
                                            autocomplete="null"
                                            :disabled="read_only"
                                        ></b-form-input>
                                        <b-input-group-append>
                                            <b-button
                                                variant="secondary"
                                                class="text-white d-flex align-items-center"
                                                @click="
                                                    showPassword = !showPassword
                                                "
                                            >
                                                <feather
                                                    :type="
                                                        showPassword
                                                            ? 'eye-off'
                                                            : 'eye'
                                                    "
                                                    size="1.1rem"
                                                ></feather>
                                            </b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                    <div
                                        class="length-field mt-1"
                                        v-if="!$v.usuario.password.$anyError"
                                    >
                                        ({{ $v.usuario.password.$model.length }}
                                        /
                                        {{
                                            $v.usuario.password.$params
                                                .maxLength.max
                                        }})
                                    </div>
                                    <b-form-invalid-feedback
                                        :state="$v.usuario.password.maxLength"
                                    >
                                        Este campo debe tener máximo
                                        {{
                                            $v.usuario.password.$params
                                                .maxLength.max
                                        }}
                                        caracteres
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                        :state="$v.usuario.password.minLength"
                                    >
                                        Este campo debe tener mínimo
                                        {{
                                            $v.usuario.password.$params
                                                .minLength.min
                                        }}
                                        caracteres
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                        :state="$v.usuario.password.required"
                                    >
                                        Campo requerido
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="mt-4">
                            <b-col cols="12">
                                <b-table
                                    :fields="fields"
                                    :items="rolesUser"
                                    :tbody-tr-class="rowClass"
                                    borderless
                                    responsive
                                    head-variant="light"
                                    class="my-2 hover-table text-nowrap"
                                    style="min-height: 38vh"
                                >
                                    <template #cell(empresa_id)="data">
                                        <b-input-group>
                                            <jautocomplete
                                                :items="empresas"
                                                item-value="id"
                                                item-text="razon_social"
                                                open-on-focus
                                                show-create-button
                                                :create-method="
                                                    () => {
                                                        roleUserIndex =
                                                            data.index
                                                        $bvModal.show(
                                                            'create-empresa-modal'
                                                        )
                                                    }
                                                "
                                                v-model="
                                                    $v.rolesUser.$each[
                                                        data.index
                                                    ].empresa_id.$model
                                                "
                                                :state="
                                                    validateStateRolesUser(
                                                        data.index,
                                                        'empresa_id'
                                                    )
                                                "
                                                @change="
                                                    ($evt) => {
                                                        getSedes(
                                                            $evt,
                                                            data.index
                                                        ),
                                                            getRoles(
                                                                $evt,
                                                                data.index
                                                            )
                                                    }
                                                "
                                                :disabled="read_only"
                                            />
                                            <div
                                                class="invalid-field mt-1"
                                                v-if="
                                                    $v.rolesUser.$each[
                                                        data.index
                                                    ].empresa_id.$error
                                                "
                                            >
                                                Campo requerido
                                            </div>
                                        </b-input-group>
                                    </template>
                                    <template #cell(sede_id)="data">
                                        <b-input-group>
                                            <jautocomplete
                                                id="sede_id"
                                                :items="
                                                    rolesUser[data.index].sedes
                                                "
                                                item-value="id"
                                                item-text="descripcion"
                                                open-on-focus
                                                show-create-button
                                                :create-method="
                                                    () => {
                                                        roleUserIndex =
                                                            data.index
                                                        $bvModal.show(
                                                            'create-sede-modal'
                                                        )
                                                    }
                                                "
                                                v-model="
                                                    $v.rolesUser.$each[
                                                        data.index
                                                    ].sede_id.$model
                                                "
                                                :state="
                                                    validateStateRolesUser(
                                                        data.index,
                                                        'sede_id'
                                                    )
                                                "
                                                :disabled="
                                                    read_only ||
                                                    rolesUser[data.index].sedes
                                                        .length === 0
                                                "
                                            />
                                            <div
                                                class="invalid-field mt-1"
                                                v-if="
                                                    !$v.rolesUser.$each[
                                                        data.index
                                                    ].sede_id.required &&
                                                    $v.rolesUser.$each[
                                                        data.index
                                                    ].sede_id.$error
                                                "
                                            >
                                                Campo requerido
                                            </div>
                                            <div
                                                class="invalid-field mt-1"
                                                v-if="
                                                    !$v.rolesUser.$each[
                                                        data.index
                                                    ].sede_id.duplicated &&
                                                    $v.rolesUser.$each[
                                                        data.index
                                                    ].sede_id.$error
                                                "
                                            >
                                                Ya hay un rol asociado a esta
                                                sede
                                            </div>
                                        </b-input-group>
                                    </template>
                                    <template #cell(role_id)="data">
                                        <b-input-group>
                                            <jautocomplete
                                                :items="
                                                    rolesUser[data.index].roles
                                                "
                                                item-value="id"
                                                item-text="descripcion"
                                                open-on-focus
                                                v-model="
                                                    $v.rolesUser.$each[
                                                        data.index
                                                    ].role_id.$model
                                                "
                                                :state="
                                                    validateStateRolesUser(
                                                        data.index,
                                                        'role_id'
                                                    )
                                                "
                                                :disabled="
                                                    read_only ||
                                                    rolesUser[data.index].roles
                                                        .length === 0
                                                "
                                                show-create-button
                                                :create-method="
                                                    () => {
                                                        roleUserIndex =
                                                            data.index
                                                        $bvModal.show(
                                                            'create-rol-modal'
                                                        )
                                                    }
                                                "
                                            />
                                            <div
                                                class="invalid-field mt-1"
                                                v-if="
                                                    $v.rolesUser.$each[
                                                        data.index
                                                    ].role_id.$error
                                                "
                                            >
                                                Campo requerido
                                            </div>
                                        </b-input-group>
                                    </template>
                                    <template #cell(acciones)="data">
                                        <feather
                                            type="plus"
                                            role="button"
                                            v-b-popover.hover.top="
                                                'Agregar rol'
                                            "
                                            @click="
                                                read_only
                                                    ? (() => {})()
                                                    : addRole()
                                            "
                                        ></feather>
                                        <feather
                                            v-if="rolesUser.length > 1"
                                            type="trash-2"
                                            role="button"
                                            class="ml-2"
                                            v-b-popover.hover.top="
                                                'Eliminar rol'
                                            "
                                            @click="
                                                read_only
                                                    ? (() => {})()
                                                    : deleteRole(data.index)
                                            "
                                        ></feather>
                                    </template>
                                </b-table>
                            </b-col>
                        </b-row>
                    </b-form>
                </div>
            </b-card>
        </b-col>
        <b-col cols="12">
            <div class="float-right d-flex flex-row">
                <b-button
                    variant="secondary"
                    :disabled="isLoading"
                    size="md"
                    class="mr-4"
                    v-b-popover.hover.top="'Presione esc'"
                    @click="back"
                >
                    Cancelar
                </b-button>

                <b-button
                    variant="primary"
                    :disabled="isLoading || read_only"
                    size="md"
                    v-b-popover.hover.top="
                        'Presione ctrl+shift+s o cmd+shift+s'
                    "
                    class="d-flex align-items-center"
                    @click="save"
                >
                    <feather
                        type="save"
                        size="1rem"
                        class="mr-2 text-blue-active"
                    />
                    <span
                        v-if="isLoading"
                        class="d-sm-flex align-items-center justify-content-center"
                    >
                        <b-spinner small></b-spinner>
                        <span class="ml-2">Guardando...</span>
                    </span>
                    <span v-else>Guardar</span>
                </b-button>
            </div>
        </b-col>
        <crearEmpresasModalVue @newEmpresa="setNewEmpresa" />
        <crearSedeModalVue
            :empresaId="
                roleUserIndex !== null
                    ? rolesUser[roleUserIndex].empresa_id
                    : null
            "
            @newSede="setNewSede"
        />
        <crearRolModalVue
            :empresaId="
                roleUserIndex !== null
                    ? rolesUser[roleUserIndex].empresa_id
                    : null
            "
            @newRol="setNeRol"
        />
    </b-row>
</template>
<script>
import services from '@/boot/axios'
import { validationMixin } from 'vuelidate'
import {
    required,
    maxLength,
    email,
    minLength,
    requiredIf
} from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import {
    onFocused,
    randomPassword,
    uniqueArryObject,
    compareObjects,
    removeListenerCommands,
    addListenerCommands
} from '@/utils/others'
import jautocomplete from '../../../../components/shared/autocomplete/jautocomplete.vue'
import crearEmpresasModalVue from '../../generales/empresas/crearEmpresasModal.vue'
import crearSedeModalVue from '../../generales/sedes/crearSedeModal.vue'
import crearRolModalVue from '../roles/crearRolModal.vue'

const { API } = services

const defaultUsuario = () =>
    JSON.parse(
        JSON.stringify({
            enabled: true,
            numero_documento: '',
            tipo_documento_id: null,
            email: '',
            nombre1: '',
            nombre2: '',
            apellido1: '',
            apellido2: '',
            telefono: '',
            username: '',
            password: randomPassword(),
            roles: []
        })
    )

export default {
    name: 'CrearUsuario',
    mixins: [validationMixin],
    components: {
        jautocomplete,
        crearEmpresasModalVue,
        crearSedeModalVue,
        crearRolModalVue
    },
    data: () => ({
        roleUserIndex: null,
        usuario: defaultUsuario(),
        usuarioCopy: defaultUsuario(),
        isLoading: false,
        isSaved: true,
        read_only: false,
        tipos_documento: [],
        showPassword: false,
        rolesUser: [],
        roles: [],
        empresas: [],
        sedes: [],
        empresasUser: [],
        sedesUser: [],
        fields: [
            { key: 'empresa_id', label: 'Empresa' },
            { key: 'sede_id', label: 'Sede' },
            { key: 'role_id', label: 'Rol' },
            { key: 'acciones', label: 'Acciones' }
        ]
    }),
    watch: {
        usuario: {
            handler(newValue) {
                this.isSaved = compareObjects(this.usuarioCopy, newValue)
            },
            deep: true
        }
    },
    mounted() {
        this.getTiposDocumento()
        this.getEmpresas()
        this.getParams()
        this.addRole()
        addListenerCommands(this)
    },
    beforeRouteLeave(to, from, next) {
        if (this.isSaved) {
            next()
        } else {
            this.$bvModal
                .msgBoxConfirm(
                    `
        Este formulario contiene información que no ha sido guardada,
        si continua con esta acción no se guardarán los cambios, desea continuar con esta acción?`,
                    {
                        title: 'Advertencia!',
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        okTitle: 'Continuar',
                        cancelTitle: 'Cancelar',
                        footerClass: 'p-2 d-flex justify-content-between',
                        hideHeaderClose: false,
                        centered: true,
                        headerBgVariant: 'danger',
                        headerClass: 'modal-header'
                    }
                )
                .then((value) => {
                    if (value) {
                        next()
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    },
    beforeDestroy() {
        removeListenerCommands(this)
    },
    validations: {
        usuario: {
            tipo_documento_id: {
                required
            },
            numero_documento: {
                required,
                minLength: minLength(4),
                maxLength: maxLength(25)
            },
            email: {
                required,
                email,
                maxLength: maxLength(150)
            },
            nombre1: {
                required,
                maxLength: maxLength(30)
            },
            nombre2: {
                maxLength: maxLength(30)
            },
            apellido1: {
                required,
                maxLength: maxLength(30)
            },
            apellido2: {
                maxLength: maxLength(30)
            },
            telefono: {
                maxLength: maxLength(15)
            },
            username: {
                required,
                minLength: minLength(4),
                maxLength: maxLength(50)
            },
            password: {
                required: requiredIf(function () {
                    return !this.usuario.id
                }),
                minLength: minLength(8),
                maxLength: maxLength(50)
            },
            enabled: {}
        },
        rolesUser: {
            minLength: minLength(1),
            required,
            $each: {
                empresa_id: {
                    required
                },
                sede_id: {
                    required,
                    duplicated: function (currentValue) {
                        const filtered = this.rolesUser.filter(
                            (r) => r.sede_id === currentValue
                        )

                        const duplicated = filtered.length === 1
                        return duplicated
                    }
                },
                role_id: {
                    required
                }
            }
        }
    },
    methods: {
        onFocused,
        showLog(data) {
            console.log(data)
        },
        setNewEmpresa(empresa) {
            this.empresas.push(empresa)
            this.rolesUser[this.roleUserIndex].empresa_id = empresa.id
        },
        setNewSede(sede) {
            this.rolesUser[this.roleUserIndex].sedes.push(sede)
            this.rolesUser[this.roleUserIndex].sede_id = sede.id
        },
        setNeRol(rol) {
            this.rolesUser[this.roleUserIndex].roles.push(rol)
            this.rolesUser[this.roleUserIndex].role_id = rol.id
        },
        getParams() {
            const { usuario_id } = this.$route.params
            if (usuario_id) {
                this.getUsuarioById(usuario_id)
            }

            this.read_only = this.$route.meta.read_only
        },
        addRole() {
            const defaultRole = {
                empresa_id: null,
                sede_id: null,
                role_id: null,
                sedes: [],
                roles: []
            }

            this.rolesUser.push(defaultRole)
        },
        deleteRole(index) {
            this.rolesUser.splice(index, 1)
        },
        getRoles(empresa_id, item_index) {
            API.POST({
                url: 'seguridad/role/query',
                data: {
                    p_datajson: {
                        empresa_id
                    },
                    p_opc: 'GET_ALL',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    // console.log(response.data);
                    if (status === 'ok') {
                        const prevData = JSON.parse(
                            JSON.stringify(this.rolesUser[item_index].roles)
                        )
                        this.rolesUser[item_index].roles = uniqueArryObject([
                            ...prevData,
                            ...dato
                        ])
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        getEmpresas() {
            API.POST({
                url: 'general/empresa/query',
                data: {
                    p_datajson: {
                        usuario_id: this.getUserData.id
                    },
                    p_opc: 'GET_ALL_USUARIO',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    // console.log(response.data);
                    if (status === 'ok') {
                        this.empresas = dato
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        getSedes(empresa_id, item_index) {
            API.POST({
                url: 'general/sede/query',
                data: {
                    p_datajson: {
                        empresa_id
                    },
                    p_opc: 'GET_ALL_EMPRESA',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        const prevData = JSON.parse(
                            JSON.stringify(this.rolesUser[item_index].sedes)
                        )
                        // console.log(dato)
                        this.rolesUser[item_index].sedes = uniqueArryObject([
                            ...prevData,
                            ...dato
                        ])
                        // this.sedes = uniqueArryObject([...this.sedes, ...dato])
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        getUsuarioById(id) {
            // console.log('acá 2');
            API.POST({
                url: 'user/query',
                data: {
                    p_datajson: {
                        id
                    },
                    p_opc: 'FIND_BY_ID',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        // this.banco = { ...dato }
                        const { password, ...rest } = dato
                        password
                        this.usuario = JSON.parse(JSON.stringify(rest))
                        // console.log(this.usuario.roles)
                        this.usuarioCopy = { ...this.usuario }
                        this.rolesUser = this.usuario.roles.map(
                            ({ empresa_id, sede_id, role_id }) => ({
                                empresa_id,
                                sede_id,
                                role_id,
                                roles: [],
                                sedes: []
                            })
                        )
                        this.rolesUser.forEach((r, index) => {
                            // console.log(r, index)
                            this.getSedes(r.empresa_id, index)
                            this.getRoles(r.empresa_id, index)
                        })
                        // console.log(rest)
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        getTiposDocumento() {
            API.POST({
                url: 'general/query',
                data: {
                    p_datajson: {},
                    p_opc: 'GET_ALL_TIPO_DOCUMENTO',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    // console.log(response.data);
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        this.tipos_documento = dato
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        save() {
            const valid =
                !this.$v.usuario.$invalid && !this.$v.rolesUser.$invalid
            if (valid) {
                this.isLoading = true
                const rolesUser = JSON.parse(JSON.stringify(this.rolesUser))
                this.usuario.roles = rolesUser.map(
                    ({ empresa_id, sede_id, role_id }) => ({
                        empresa_id,
                        sede_id,
                        role_id
                    })
                )
                const usuario = JSON.parse(JSON.stringify(this.usuario))
                const p_opc = this.usuario.id ? 'UPDATE' : 'INSERT'
                if (p_opc === 'UPDATE') {
                    usuario.password = null
                }
                API.POST({
                    url: 'user/crud',
                    data: {
                        p_datajson: {
                            ...usuario
                        },
                        p_opc,
                        p_auditoriajson: this.auditoriajson
                    }
                })
                    .then((response) => {
                        const {
                            data: {
                                status: { status, mensaje, mensaje_exception }
                            }
                        } = response
                        this.isLoading = false
                        if (status === 'ok') {
                            this.isSaved = true
                            this.back()
                        } else {
                            this.$bvModal
                                .msgBoxOk(
                                    mensaje ||
                                        mensaje_exception ||
                                        'Ha ocurrido un error!',
                                    {
                                        title: 'Error!',
                                        size: 'sm',
                                        buttonSize: 'sm',
                                        okVariant: 'error',
                                        okTitle: 'Aceptar',
                                        cancelTitle: 'Cancelar',
                                        footerClass: 'p-2',
                                        hideHeaderClose: false,
                                        centered: true,
                                        headerBgVariant: 'error',
                                        headerClass: 'modal-header'
                                    }
                                )
                                .then(() => {
                                    // console.log(value);
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        }
                    })
                    .catch((error) => {
                        this.isLoading = false
                        const { message } = error
                        this.$bvModal
                            .msgBoxOk(message || 'Ha ocurrido un error!', {
                                title: 'Error!',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'error',
                                okTitle: 'Aceptar',
                                cancelTitle: 'Cancelar',
                                footerClass: 'p-2',
                                hideHeaderClose: false,
                                centered: true,
                                headerBgVariant: 'error',
                                headerClass: 'modal-header',
                                noStacking: true
                            })
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    })
            } else {
                this.$v.usuario.$touch()
                this.$v.rolesUser.$touch()
            }
        },
        back() {
            this.$router.back()
        },
        validateState(key) {
            const { $dirty, $error } = this.$v.usuario[key]
            return $dirty ? !$error : null
        },
        rowClass(item, type) {
            if (!item || type !== 'row') return
            if (item.status === 'active') return 'active'
        },
        validateStateRolesUser(index, key) {
            const { $dirty, $error } = this.$v.rolesUser.$each[index][key]
            return $dirty ? !$error : null
        }
    },
    computed: {
        keymap() {
            if (
                navigator.platform.toLowerCase().includes('mac') ||
                navigator.platform.toLowerCase().includes('macintosh')
            ) {
                return {
                    'command+shift+s': this.read_only ? () => {} : this.save,
                    esc: this.back
                }
            } else if (
                navigator.platform.toLowerCase().includes('win') ||
                navigator.platform.toLowerCase().includes('windows')
            ) {
                return {
                    'ctrl+shift+s': this.read_only ? () => {} : this.save,
                    esc: this.back
                }
            } else {
                return {}
            }
        },
        auditoriajson() {
            const json = { ...this.getAuditoriaJson, modulo_id: 28 }
            return json
        },
        ...mapGetters('usuario', ['getUserData', 'getAuditoriaJson']),
        ...mapGetters('empresas', ['getUltimaEmpresa'])
    }
}
</script>

<style></style>
