<template>
    <b-modal
        id="create-sede-modal"
        header-bg-variant="primary"
        hide-footer
        no-close-on-backdrop
        :body-class="`${Theme === 'dark' && 'dark'} dialog2`"
        dialog-class="dialog"
        centered
        title="Crear sede"
        @shown="openModal"
        @close="resetFom"
    >
        <b-card class="mb-4 w-100">
            <div>
                <b-form class="p-5">
                    <!-- title="Info" -->
                    <div class="d-flex">
                        <div>
                            <h3 class="mb-2">Datos de la sede</h3>
                            <div class="text-gray mb-5">
                                Por favor registre los datos de su sede para
                                continuar
                            </div>
                        </div>
                    </div>
                    <b-row>
                        <b-col cols="12" md="4" lg="4" xl="4">
                            <b-form-group>
                                <label for="codigo"
                                    >Código
                                    <small style="color: red">*</small></label
                                >
                                <b-form-input
                                    id="codigo"
                                    type="text"
                                    placeholder="Ej: 001"
                                    @focus="onFocused"
                                    v-model="$v.sede.codigo.$model"
                                    :state="validateState('codigo')"
                                    :disabled="read_only"
                                >
                                </b-form-input>
                                <!-- @keyup.enter="goToNextField('descripcion')"
                                @keyup.tab="goToNextField('descripcion')" -->
                                <div
                                    class="length-field mt-1"
                                    v-if="!$v.sede.codigo.$anyError"
                                >
                                    ({{ $v.sede.codigo.$model.length }} /
                                    {{ $v.sede.codigo.$params.maxLength.max }})
                                </div>
                                <b-form-invalid-feedback
                                    v-if="!$v.sede.codigo.maxLength"
                                >
                                    Este campo debe tener máximo
                                    {{ $v.sede.codigo.$params.maxLength.max }}
                                    caracteres
                                </b-form-invalid-feedback>
                                <b-form-invalid-feedback
                                    v-if="!$v.sede.codigo.required"
                                >
                                    Campo requerido
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="8" lg="8" xl="8">
                            <b-form-group>
                                <label for="descripcion"
                                    >Descripción
                                    <small style="color: red">*</small></label
                                >
                                <b-form-input
                                    id="descripcion"
                                    type="text"
                                    placeholder="Ej: Nombre del banco"
                                    @focus="onFocused"
                                    v-model="$v.sede.descripcion.$model"
                                    :state="validateState('descripcion')"
                                    :disabled="read_only"
                                >
                                </b-form-input>
                                <!-- @keyup.enter="goToNextField('municipio_id')"
                                @keyup.tab="goToNextField('municipio_id')" -->
                                <div
                                    class="length-field mt-1"
                                    v-if="!$v.sede.descripcion.$anyError"
                                >
                                    ({{ $v.sede.descripcion.$model.length }}
                                    /
                                    {{
                                        $v.sede.descripcion.$params.maxLength
                                            .max
                                    }})
                                </div>
                                <b-form-invalid-feedback
                                    v-if="!$v.sede.descripcion.maxLength"
                                >
                                    Este campo debe tener máximo
                                    {{
                                        $v.sede.descripcion.$params.maxLength
                                            .max
                                    }}
                                    caracteres
                                </b-form-invalid-feedback>
                                <b-form-invalid-feedback
                                    v-if="!$v.sede.descripcion.required"
                                >
                                    Campo requerido
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row class="mt-md-4">
                        <b-col cols="12" md="4" lg="4" xl="4">
                            <b-form-group>
                                <label for="direccion"
                                    >Dirección
                                    <small style="color: red">*</small></label
                                >
                                <b-form-input
                                    autocomplete="null"
                                    id="direccion"
                                    type="text"
                                    placeholder="Ej: calle 27 #38-43"
                                    v-model="$v.sede.direccion.$model"
                                    :state="validateState('direccion')"
                                    :disabled="read_only"
                                ></b-form-input>
                                <div
                                    class="length-field mt-1"
                                    v-if="!$v.sede.direccion.$anyError"
                                >
                                    ({{ $v.sede.direccion.$model.length }} /
                                    {{
                                        $v.sede.direccion.$params.maxLength.max
                                    }})
                                </div>
                                <b-form-invalid-feedback
                                    v-if="!$v.sede.direccion.maxLength"
                                >
                                    Este campo debe tener máximo
                                    {{
                                        $v.sede.direccion.$params.maxLength.max
                                    }}
                                    caracteres
                                </b-form-invalid-feedback>
                                <b-form-invalid-feedback
                                    v-if="!$v.sede.direccion.required"
                                >
                                    Campo requerido
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="4" lg="4" xl="4">
                            <b-form-group>
                                <label for="municipio_id"
                                    >Ciudad
                                    <small style="color: red">*</small></label
                                >
                                <jautocomplete
                                    id="municipio_id"
                                    :items="municipios"
                                    item-text="descripcion"
                                    item-value="id"
                                    open-on-focus
                                    v-model="$v.sede.municipio_id.$model"
                                    :state="validateState('municipio_id')"
                                    :disabled="read_only"
                                />
                                <div
                                    class="invalid-field mt-1"
                                    v-if="$v.sede.municipio_id.$error"
                                >
                                    Campo requerido
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" sm="4">
                            <label for="bodega_id">Bodega</label>
                            <jautocomplete
                                id="bodega_id"
                                :items="bodegas"
                                item-value="id"
                                item-text="descripcion"
                                open-on-focus
                                v-model="$v.sede.bodega_id.$model"
                                :state="validateState('bodega_id')"
                            >
                                <template v-slot:option="{ item }">
                                    <span
                                        >{{ item.codigo }} -
                                        {{ item.descripcion }}</span
                                    >
                                </template>
                            </jautocomplete>
                            <div
                                class="invalid-field mt-1"
                                v-if="$v.sede.bodega_id.$error"
                            >
                                Campo requerido
                            </div>
                        </b-col>
                    </b-row>

                    <b-row class="mt-md-4">
                        <b-col cols="12" sm="6">
                            <label for="lote_inventario_id"
                                >Lote inventario
                                <small style="color: red">*</small>
                            </label>
                            <jautocomplete
                                id="lote_inventario_id"
                                :items="lotesInventario"
                                item-value="id"
                                item-text="nombre"
                                open-on-focus
                                v-model="$v.sede.lote_inventario_id.$model"
                                :disabled="read_only"
                                :state="validateState('lote_inventario_id')"
                            >
                                <template v-slot:option="{ item }">
                                    <span
                                        >{{ item.codigo }} -
                                        {{ item.nombre }}</span
                                    >
                                </template>
                            </jautocomplete>
                            <div
                                class="invalid-field mt-1"
                                v-if="$v.sede.lote_inventario_id.$error"
                            >
                                Campo requerido
                            </div>
                        </b-col>

                        <b-col cols="12" sm="6">
                            <label for="centro_costo_id"
                                >Centro de costo
                                <small style="color: red">*</small>
                            </label>
                            <jautocomplete
                                id="centro_costo_id"
                                :items="centrosCosto"
                                item-value="id"
                                item-text="descripcion"
                                open-on-focus
                                v-model="$v.sede.centro_costo_id.$model"
                                :disabled="read_only"
                                :state="validateState('centro_costo_id')"
                            >
                                <template v-slot:option="{ item }">
                                    <span
                                        >{{ item.codigo }} -
                                        {{ item.descripcion }}</span
                                    >
                                </template>
                            </jautocomplete>
                            <div
                                class="invalid-field mt-1"
                                v-if="$v.sede.centro_costo_id.$error"
                            >
                                Campo requerido
                            </div>
                        </b-col>
                    </b-row>

                    <b-row class="mt-md-4">
                        <b-col cols="12">
                            <div class="float-right d-flex flex-row">
                                <b-button
                                    variant="primary"
                                    :disabled="isLoading || read_only"
                                    size="md"
                                    v-b-popover.hover.top="
                                        'Presione ctrl+shift+s o cmd+shift+s'
                                    "
                                    class="d-flex align-items-center"
                                    @click="save"
                                >
                                    <feather
                                        type="save"
                                        size="1rem"
                                        class="mr-2 text-blue-active"
                                    />
                                    <span
                                        v-if="isLoading"
                                        class="d-sm-flex align-items-center justify-content-center"
                                    >
                                        <b-spinner small></b-spinner>
                                        <span class="ml-2">Guardando...</span>
                                    </span>
                                    <span v-else>Guardar</span>
                                </b-button>
                            </div>
                        </b-col>
                    </b-row>
                </b-form>
            </div>
        </b-card>
    </b-modal>
</template>
<script>
import services from '@/boot/axios'
import { validationMixin } from 'vuelidate'
import { required, maxLength } from 'vuelidate/lib/validators'
import { mapGetters, mapState, mapMutations } from 'vuex'
import jautocomplete from '@/components/shared/autocomplete/jautocomplete'
import {
    removeListenerCommands,
    addListenerCommands,
    onFocused,
    goToNextField,
    compareObjects
} from '@/utils/others'

const { API } = services

const defaultSede = () =>
    JSON.parse(
        JSON.stringify({
            codigo: '',
            descripcion: '',
            direccion: '',
            empresa_id: 3,
            municipio_id: null,
            bodega_id: null,
            lote_inventario_id: 0,
            centro_costo_id: '0'
        })
    )

export default {
    name: 'CrearSedeModal',
    mixins: [validationMixin],
    components: { jautocomplete },
    data: () => ({
        sedeCopy: defaultSede(),
        sede: defaultSede(),
        isLoading: false,
        isSaved: true,
        read_only: false,
        municipios: [],
        bodegas: [],
        lotesInventario: [],
        centrosCosto: []
    }),
    watch: {
        sede: {
            handler(newValue) {
                this.isSaved = compareObjects(this.sedeCopy, newValue)
            },
            deep: true
        }
    },
    props: {
        empresaId: {
            type: Number,
            default: function () {
                return this.getUltimaEmpresa.id
            }
        }
    },
    mounted() {
        addListenerCommands(this)
        this.getParams()
        this.getLotesInventario()
        this.getBodegas()
        this.getCentrosCosto()
        this.getMunicipios(this.getUltimaEmpresa.pais.id)
    },
    beforeDestroy() {
        removeListenerCommands(this)
    },
    beforeRouteLeave(to, from, next) {
        if (this.isSaved) {
            next()
        } else {
            this.$bvModal
                .msgBoxConfirm(
                    `
        Este formulario contiene información que no ha sido guardada,
        si continua con esta acción no se guardarán los cambios, desea continuar con esta acción?`,
                    {
                        title: 'Advertencia!',
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        okTitle: 'Continuar',
                        cancelTitle: 'Cancelar',
                        footerClass: 'p-2 d-flex justify-content-between',
                        hideHeaderClose: false,
                        centered: true,
                        headerBgVariant: 'danger',
                        headerClass: 'modal-header'
                    }
                )
                .then((value) => {
                    if (value) {
                        next()
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    },
    validations: {
        sede: {
            codigo: {
                required,
                maxLength: maxLength(25)
            },
            descripcion: {
                required,
                maxLength: maxLength(150)
            },
            direccion: {
                required,
                maxLength: maxLength(150)
            },
            municipio_id: {
                required
            },
            bodega_id: {
                required
            },
            lote_inventario_id: {
                required
            },
            centro_costo_id: {
                required
            }
        }
    },
    methods: {
        onFocused,
        goToNextField,
        getLotesInventario() {
            API.POST({
                url: 'administracion/inventario/lote-inventario/query',
                data: {
                    p_datajson: {
                        empresa_id: this.getUltimaEmpresa.id
                    },
                    p_opc: 'GET_ALL',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        // console.log(dato)
                        this.lotesInventario = dato
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        getCentrosCosto() {
            API.POST({
                url: 'contabilidad/centro-costo/all',
                data: {
                    empresaId: this.getUltimaEmpresa.id
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        // console.log(dato)
                        this.centrosCosto = dato
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        getMunicipios(pais_id) {
            API.POST({
                url: 'general/municipio/query',
                data: {
                    p_datajson: {
                        pais_id
                    },
                    p_opc: 'GET_ALL',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        this.municipios = dato
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        getParams() {
            const { sede_id } = this.$route.params
            if (sede_id) {
                this.getSedeById(sede_id)
            }

            this.read_only = this.$route.meta.read_only
        },
        getSedeById(id) {
            // console.log('acá 2');
            API.POST({
                url: 'general/sede/query',
                data: {
                    p_datajson: {
                        id
                    },
                    p_opc: 'FIND_BY_ID',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        this.sedeCopy = { ...dato }
                        this.sede = { ...dato }
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        getBodegas() {
            API.POST({
                url: 'administracion/inventario/bodega/query',
                data: {
                    p_datajson: {
                        empresa_id: this.getUltimaEmpresa.id
                    },
                    p_opc: 'GET_ALL',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        this.bodegas = [...dato]
                        // console.log(this.bodegas)
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        save() {
            const valid = !this.$v.sede.$invalid
            if (valid) {
                this.isLoading = true
                this.sede.empresa_id = this.empresaId
                const p_opc = this.sede.id ? 'UPDATE' : 'INSERT'
                API.POST({
                    url: 'general/sede/crud',
                    data: {
                        p_datajson: {
                            ...this.sede
                        },
                        p_opc,
                        p_auditoriajson: this.auditoriajson
                    }
                })
                    .then((response) => {
                        const {
                            data: {
                                dato,
                                status: { status, mensaje, mensaje_exception }
                            }
                        } = response
                        this.isLoading = false
                        if (status === 'ok') {
                            this.isSaved = true
                            // this.empresa = defaultEmpresa()
                            // console.log(response.data)
                            this.$emit('newSede', dato)
                            this.sede = defaultSede()
                            this.$v.sede.$reset()
                            this.close()
                        } else {
                            this.$bvModal
                                .msgBoxOk(
                                    mensaje ||
                                        mensaje_exception ||
                                        'Ha ocurrido un error!',
                                    {
                                        title: 'Error!',
                                        size: 'sm',
                                        buttonSize: 'sm',
                                        okVariant: 'error',
                                        okTitle: 'Aceptar',
                                        cancelTitle: 'Cancelar',
                                        footerClass: 'p-2',
                                        hideHeaderClose: false,
                                        centered: true,
                                        headerBgVariant: 'error',
                                        headerClass: 'modal-header'
                                    }
                                )
                                .then(() => {
                                    // console.log(value);
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        }
                    })
                    .catch((error) => {
                        this.isLoading = false
                        const { message } = error
                        this.$bvModal
                            .msgBoxOk(message || 'Ha ocurrido un error!', {
                                title: 'Error!',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'error',
                                okTitle: 'Aceptar',
                                cancelTitle: 'Cancelar',
                                footerClass: 'p-2',
                                hideHeaderClose: false,
                                centered: true,
                                headerBgVariant: 'error',
                                headerClass: 'modal-header',
                                noStacking: true
                            })
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    })
            } else {
                this.$v.sede.$touch()
            }
        },
        validateState(key) {
            const { $dirty, $error } = this.$v.sede[key]
            return $dirty ? !$error : null
        },
        openModal() {
            this.goToNextField('codigo')
            // const { empresa_id } = this.$route.params
            // if (empresa_id) {
            //     this.getRetecrees(empresa_id)
            // } else {
            //     this.getRetecrees()
            // }
        },
        resetFom() {
            this.sede = defaultSede()
            this.$v.sede.$reset()
        },
        close() {
            this.$bvModal.hide('create-sede-modal')
        },
        ...mapMutations('empresas', ['setUltimaEmpresa'])
    },
    computed: {
        auditoriajson() {
            const json = { ...this.getAuditoriaJson, modulo_id: 5 }
            return json
        },
        ...mapGetters('usuario', ['getUserData', 'getAuditoriaJson']),
        ...mapGetters('usuario', ['getUserData']),
        ...mapGetters('empresas', ['getUltimaEmpresa']),
        ...mapState(['Theme'])
    }
}
</script>

<style>
.dialog {
    min-width: 80%;
}

.dialog2 {
    min-height: auto;
}
</style>
